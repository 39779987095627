import React from 'react';
import { appEvents } from 'app/core/core';
import { Icon } from '@grafana/ui';

export class HelpModal extends React.PureComponent {
  static tabIndex = 0;
  static categoryName = ['グローバル', 'ダッシュボード', 'パネル切替', '時間範囲'];
  static shortcuts = {
    Global: [
      { keys: ['g', 'h'], description: 'ホームダッシュボードへ移動' },
      { keys: ['g', 'p'], description: 'プロファイルへ移動' },
      { keys: ['s', 'o'], description: '検索画面を開く' },
      { keys: ['esc'], description: '編集/設定画面を閉じる' },
    ],
    Dashboard: [
      { keys: ['mod+s'], description: 'ダッシュボードを保存' },
      { keys: ['d', 'r'], description: '全てのパネルを更新' },
      { keys: ['d', 's'], description: 'ダッシュボード設定' },
      { keys: ['d', 'v'], description: '非アクティブ/ビューモード切替' },
      { keys: ['d', 'k'], description: 'キオスクモードに切替(上部ナビを非表示)' },
      { keys: ['d', 'E'], description: '全ての行を開く' },
      { keys: ['d', 'C'], description: '全ての行を閉じる' },
      { keys: ['d', 'a'], description: '自動調整パネルの切替(実験的機能)' },
      { keys: ['mod+o'], description: '共有グラフの十字線の切替' },
      { keys: ['d', 'l'], description: '全パネル凡例切替' },
    ],
    'Focused Panel': [
      { keys: ['e'], description: 'パネル編集画面に切替' },
      { keys: ['v'], description: 'パネル全画面表示に切替' },
      { keys: ['p', 's'], description: 'パネル共有モーダルを開く' },
      { keys: ['p', 'd'], description: 'パネルの複製' },
      { keys: ['p', 'r'], description: 'パネルの削除' },
      { keys: ['p', 'l'], description: 'パネル凡例切替' },
    ],
    'Time Range': [
      { keys: ['t', 'z'], description: '時間帯をズームアウト' },
      {
        keys: ['t', '←'],
        description: '前の時間帯へ移動',
      },
      {
        keys: ['t', '→'],
        description: '次の時間帯へ移動',
      },
    ],
  };

  dismiss() {
    appEvents.emit('hide-modal');
  }

  render() {
    return (
      <div className="modal-body">
        <div className="modal-header">
          <h2 className="modal-header-title">
            <Icon name="keyboard" size="lg" />
            <span className="p-l-1">ショートカット</span>
          </h2>
          <a className="modal-header-close" onClick={this.dismiss}>
            <Icon name="times" style={{ margin: '3px 0 0 0' }} />
          </a>
        </div>

        <div className="modal-content help-modal">
          <p className="small" style={{ position: 'absolute', top: '13px', right: '44px' }}>
            <span className="shortcut-table-key">mod</span> =
            <span className="muted"> WindowsとLinuxの場合は[CTRL]、Macの場合は[CMD]</span>
          </p>

          {Object.entries(HelpModal.shortcuts).map(([category, shortcuts], i) => (
            <div className="shortcut-category" key={i}>
              <table className="shortcut-table">
                <tbody>
                  <tr>
                    <th className="shortcut-table-category-header" colSpan={2}>
                      {HelpModal.categoryName[i]}
                    </th>
                  </tr>
                  {shortcuts.map((shortcut, j) => (
                    <tr key={`${i}-${j}`}>
                      <td className="shortcut-table-keys">
                        {shortcut.keys.map((key, k) => (
                          <span className="shortcut-table-key" key={`${i}-${j}-${k}`}>
                            {key}
                          </span>
                        ))}
                      </td>
                      <td className="shortcut-table-description">{shortcut.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}

          <div className="clearfix" />
        </div>
      </div>
    );
  }
}
