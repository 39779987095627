import React, { FC } from 'react';
import { css, cx } from 'emotion';
import { useTheme } from '@grafana/ui';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/oki_logo.svg" alt="OKI" />;
};

const ServiceLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/service_logo.png" alt="monifi" />;
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  /* const theme = useTheme(); */
  const background = css`
    background: url(public/img/login_background_oki.jpg);
    background-size: cover;
  `;

  return <div className={cx(background, className)}>{children}</div>;
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/service_icon.svg" alt="monifi" />;
};

const LoginBoxBackground = () => {
  const theme = useTheme();
  return css`
    background: ${theme.isLight ? 'rgba(6, 30, 200, 0.1 )' : 'rgba(18, 28, 41, 0.65)'};
    background-size: cover;
  `;
};

export class Branding {
  static LoginLogo = LoginLogo;
  static ServiceLogo = ServiceLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'monifi';
  static LoginTitle = 'Welcome to monifi';
  static GetLoginSubTitle = () => {
    const slogans = [
      'Monitoring Service for Infrastructure',
      'Powered by Grafana',
      'Built better together',
      'Visualize your sensing data',
    ];
    const count = slogans.length;
    return slogans[Math.floor(Math.random() * count)];
  };
}
