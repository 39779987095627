import { TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now-5m', to: 'now', display: '直近 5 分', section: 3 },
  { from: 'now-15m', to: 'now', display: '直近 15 分', section: 3 },
  { from: 'now-30m', to: 'now', display: '直近 30 分', section: 3 },
  { from: 'now-1h', to: 'now', display: '直近 1 時間', section: 3 },
  { from: 'now-3h', to: 'now', display: '直近 3 時間', section: 3 },
  { from: 'now-6h', to: 'now', display: '直近 6 時間', section: 3 },
  { from: 'now-12h', to: 'now', display: '直近 12 時間', section: 3 },
  { from: 'now-24h', to: 'now', display: '直近 24 時間', section: 3 },
  { from: 'now-2d', to: 'now', display: '直近 2 日', section: 3 },
  { from: 'now-7d', to: 'now', display: '直近 7 日', section: 3 },
  { from: 'now-30d', to: 'now', display: '直近 30 日', section: 3 },
  { from: 'now-90d', to: 'now', display: '直近 90 日', section: 3 },
  { from: 'now-6M', to: 'now', display: '直近 6 ヵ月', section: 3 },
  { from: 'now-1y', to: 'now', display: '直近 1 年', section: 3 },
  { from: 'now-2y', to: 'now', display: '直近 2 年', section: 3 },
  { from: 'now-5y', to: 'now', display: '直近 5 年', section: 3 },
];

export const otherOptions: TimeOption[] = [
  { from: 'now-1d/d', to: 'now-1d/d', display: '昨日', section: 3 },
  { from: 'now-2d/d', to: 'now-2d/d', display: '一昨日', section: 3 },
  { from: 'now-7d/d', to: 'now-7d/d', display: '先週の同じ曜日', section: 3 },
  { from: 'now-1w/w', to: 'now-1w/w', display: '先週', section: 3 },
  { from: 'now-1M/M', to: 'now-1M/M', display: '前月', section: 3 },
  { from: 'now-1y/y', to: 'now-1y/y', display: '前年', section: 3 },
  { from: 'now/d', to: 'now/d', display: '今日', section: 3 },
  { from: 'now/d', to: 'now', display: '今日以降', section: 3 },
  { from: 'now/w', to: 'now/w', display: '今週', section: 3 },
  { from: 'now/w', to: 'now', display: '今週以降', section: 3 },
  { from: 'now/M', to: 'now/M', display: '今月', section: 3 },
  { from: 'now/M', to: 'now', display: '今月以降', section: 3 },
  { from: 'now/y', to: 'now/y', display: '今年', section: 3 },
  { from: 'now/y', to: 'now', display: '今年以降', section: 3 },
];
