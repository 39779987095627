import React from 'react';
import { Button, InfoBox, Portal, stylesFactory, useTheme } from '@grafana/ui';
import { getModalStyles } from '@grafana/ui/src/components/Modal/getModalStyles';
import { css, cx } from 'emotion';
import { GrafanaTheme } from '@grafana/data';

interface Props {
  maxConcurrentSessions?: number;
}

export const TokenRevokedModal = (props: Props) => {
  const theme = useTheme();

  const styles = getStyles(theme);
  const modalStyles = getModalStyles(theme);

  const showMaxConcurrentSessions = Boolean(props.maxConcurrentSessions);

  const redirectToLogin = () => {
    window.location.reload();
  };

  return (
    <Portal>
      <div className={modalStyles.modal}>
        <InfoBox title="自動的にログアウトされました" severity="warning" className={styles.infobox}>
          <div className={styles.text}>
            <p>
              アカウントの
              <strong>
                {` 最大同時セッション数 ${showMaxConcurrentSessions ? props.maxConcurrentSessions : ''} `}
              </strong>
              に達したため、セッションは自動的に取り消されました。
            </p>
            <p>
              <strong>セッションを再開するには、再ログインしてください。</strong>
              自動サインアウトを繰り返す場合は、管理者に連絡するか、
              ライセンス・ページにアクセスしてクォータを確認してください。
            </p>
          </div>
          <Button size="md" variant="primary" onClick={redirectToLogin}>
            ログイン
          </Button>
        </InfoBox>
      </div>
      <div className={cx(modalStyles.modalBackdrop, styles.backdrop)} />
    </Portal>
  );
};

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  return {
    infobox: css`
      margin-bottom: 0;
    `,
    text: css`
      margin: ${theme.spacing.sm} 0 ${theme.spacing.md};
    `,
    backdrop: css`
      background-color: ${theme.colors.dashboardBg};
      opacity: 0.8;
    `,
  };
});
