import {
  SingleStatBaseOptions,
  BigValueColorMode,
  BigValueGraphMode,
  BigValueJustifyMode,
  BigValueTextMode,
} from '@grafana/ui';
import {
  ReducerID,
  standardEditorsRegistry,
  FieldOverrideContext,
  getFieldDisplayName,
  escapeStringForRegex,
  VizOrientation,
  PanelOptionsEditorBuilder,
} from '@grafana/data';

// Structure copied from angular
export interface StatPanelOptions extends SingleStatBaseOptions {
  graphMode: BigValueGraphMode;
  colorMode: BigValueColorMode;
  justifyMode: BigValueJustifyMode;
  textMode: BigValueTextMode;
}

export function addStandardDataReduceOptions<T extends SingleStatBaseOptions>(
  builder: PanelOptionsEditorBuilder<T>,
  includeOrientation = true,
  includeFieldMatcher = true,
  includeTextSizes = true
) {
  builder.addRadio({
    path: 'reduceOptions.values',
    name: '表示',
    description: '列/系列毎に単一の値を計算するか、すべての行を表示します',
    settings: {
      options: [
        { value: false, label: '計算' },
        { value: true, label: 'すべての値' },
      ],
    },
    defaultValue: false,
  });

  builder.addNumberInput({
    path: 'reduceOptions.limit',
    name: '上限',
    description: '最大表示数',
    settings: {
      placeholder: '5000',
      integer: true,
      min: 1,
      max: 5000,
    },
    showIf: (options) => options.reduceOptions.values === true,
  });

  builder.addCustomEditor({
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: '計算',
    description: '関数/計算を選択します',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.lastNotNull],
    // Hides it when all values mode is on
    showIf: (currentConfig) => currentConfig.reduceOptions.values === false,
  });

  if (includeFieldMatcher) {
    builder.addSelect({
      path: 'reduceOptions.fields',
      name: 'フィールド',
      description: 'パネルに含めるフィールドを選択します',
      settings: {
        allowCustomValue: true,
        options: [],
        getOptions: async (context: FieldOverrideContext) => {
          const options = [
            { value: '', label: '数値フィールド' },
            { value: '/.*/', label: '全てのフィールド' },
          ];
          if (context && context.data) {
            for (const frame of context.data) {
              for (const field of frame.fields) {
                const name = getFieldDisplayName(field, frame, context.data);
                const value = `/^${escapeStringForRegex(name)}$/`;
                options.push({ value, label: name });
              }
            }
          }
          return Promise.resolve(options);
        },
      },
      defaultValue: '',
    });
  }

  if (includeOrientation) {
    builder.addRadio({
      path: 'orientation',
      name: '配置方向',
      description: '複数系列/フィールドの場合の積み重ね方向',
      settings: {
        options: [
          { value: VizOrientation.Auto, label: '自動' },
          { value: VizOrientation.Horizontal, label: '水平方向' },
          { value: VizOrientation.Vertical, label: '垂直方向' },
        ],
      },
      defaultValue: VizOrientation.Auto,
    });
  }

  if (includeTextSizes) {
    builder.addNumberInput({
      path: 'text.titleSize',
      category: ['文字サイズ'],
      name: 'タイトル',
      settings: {
        placeholder: 'Auto',
        integer: false,
        min: 1,
        max: 200,
      },
      defaultValue: undefined,
    });

    builder.addNumberInput({
      path: 'text.valueSize',
      category: ['文字サイズ'],
      name: '値',
      settings: {
        placeholder: 'Auto',
        integer: false,
        min: 1,
        max: 200,
      },
      defaultValue: undefined,
    });
  }
}
