import React, { useState } from 'react';
import { css } from 'emotion';
import { CollapsableSection, Button, TagsInput, Select, Field, Input, Checkbox } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { LinkSettingsMode } from '../DashboardSettings/LinksSettings';
import { DashboardLink, DashboardModel } from '../../state/DashboardModel';

const newLink = {
  icon: 'external link',
  title: '',
  tooltip: '',
  type: 'dashboards',
  url: '',
  asDropdown: false,
  tags: [],
  targetBlank: false,
  keepTime: false,
  includeVars: false,
} as DashboardLink;

const linkTypeOptions = [
  { value: 'dashboards', label: 'ダッシュボード' },
  { value: 'link', label: 'リンク' },
];

export const linkIconMap: { [key: string]: string } = {
  'external link': 'external-link-alt',
  dashboard: 'apps',
  question: 'question-circle',
  info: 'info-circle',
  bolt: 'bolt',
  doc: 'file-alt',
  cloud: 'cloud',
};

const linkIconOptions = Object.keys(linkIconMap).map((key) => ({ label: key, value: key }));

type LinkSettingsEditProps = {
  mode: LinkSettingsMode;
  editLinkIdx: number | null;
  dashboard: DashboardModel;
  backToList: () => void;
};

export const LinkSettingsEdit: React.FC<LinkSettingsEditProps> = ({ mode, editLinkIdx, dashboard, backToList }) => {
  const [linkSettings, setLinkSettings] = useState(editLinkIdx !== null ? dashboard.links[editLinkIdx] : newLink);

  const onTagsChange = (tags: any[]) => {
    setLinkSettings((link) => ({ ...link, tags: tags }));
  };

  const onTypeChange = (selectedItem: SelectableValue) => {
    setLinkSettings((link) => ({ ...link, type: selectedItem.value }));
  };

  const onIconChange = (selectedItem: SelectableValue) => {
    setLinkSettings((link) => ({ ...link, icon: selectedItem.value }));
  };

  const onChange = (ev: React.FocusEvent<HTMLInputElement>) => {
    const target = ev.currentTarget;
    setLinkSettings((link) => ({
      ...link,
      [target.name]: target.type === 'checkbox' ? target.checked : target.value,
    }));
  };

  const addLink = () => {
    dashboard.links = [...dashboard.links, linkSettings];
    dashboard.updateSubmenuVisibility();
    backToList();
  };

  const updateLink = () => {
    dashboard.links.splice(editLinkIdx!, 1, linkSettings);
    dashboard.updateSubmenuVisibility();
    backToList();
  };

  return (
    <div
      className={css`
        max-width: 600px;
      `}
    >
      <Field label="リンク種別">
        <Select value={linkSettings.type} options={linkTypeOptions} onChange={onTypeChange} />
      </Field>
      <Field label="タイトル">
        <Input name="title" aria-label="title" value={linkSettings.title} onChange={onChange} />
      </Field>
      {linkSettings.type === 'dashboards' && (
        <>
          <Field label="タグ付き">
            <TagsInput tags={linkSettings.tags} placeholder="タグを追加" onChange={onTagsChange} />
          </Field>
        </>
      )}
      {linkSettings.type === 'link' && (
        <>
          <Field label="URL">
            <Input name="url" value={linkSettings.url} onChange={onChange} />
          </Field>
          <Field label="ツールチップ">
            <Input name="tooltip" value={linkSettings.tooltip} onChange={onChange} placeholder="Open dashboard" />
          </Field>
          <Field label="アイコン">
            <Select value={linkSettings.icon} options={linkIconOptions} onChange={onIconChange} />
          </Field>
        </>
      )}
      <CollapsableSection label="オプション" isOpen={true}>
        {linkSettings.type === 'dashboards' && (
          <Field>
            <Checkbox
              label="ドロップダウンとして表示"
              name="asDropdown"
              value={linkSettings.asDropdown}
              onChange={onChange}
            />
          </Field>
        )}
        <Field>
          <Checkbox label="現在の時刻範囲を含む" name="keepTime" value={linkSettings.keepTime} onChange={onChange} />
        </Field>
        <Field>
          <Checkbox
            label="現在のテンプレート変数値を含む"
            name="includeVars"
            value={linkSettings.includeVars}
            onChange={onChange}
          />
        </Field>
        <Field>
          <Checkbox
            label="新しいタブでリンクを開く"
            name="targetBlank"
            value={linkSettings.targetBlank}
            onChange={onChange}
          />
        </Field>
      </CollapsableSection>

      <div className="gf-form-button-row">
        {mode === 'new' && <Button onClick={addLink}>追加</Button>}
        {mode === 'edit' && <Button onClick={updateLink}>更新</Button>}
      </div>
    </div>
  );
};
