import { PanelPlugin, LogsSortOrder, LogsDedupStrategy, LogsDedupDescription } from '@grafana/data';
import { Options } from './types';
import { LogsPanel } from './LogsPanel';

export const plugin = new PanelPlugin<Options>(LogsPanel).setPanelOptions((builder) => {
  builder
    .addBooleanSwitch({
      path: 'showTime',
      name: '時刻',
      description: '',
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showLabels',
      name: 'ユニークラベル',
      description: '',
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'wrapLogMessage',
      name: '行折り返し',
      description: '',
      defaultValue: false,
    })
    .addRadio({
      path: 'dedupStrategy',
      name: '重複排除',
      description: '',
      settings: {
        options: [
          { value: LogsDedupStrategy.none, label: 'なし', description: LogsDedupDescription[LogsDedupStrategy.none] },
          {
            value: LogsDedupStrategy.exact,
            label: '完全一致',
            description: LogsDedupDescription[LogsDedupStrategy.exact],
          },
          {
            value: LogsDedupStrategy.numbers,
            label: '値無視',
            description: LogsDedupDescription[LogsDedupStrategy.numbers],
          },
          {
            value: LogsDedupStrategy.signature,
            label: '特徴一致',
            description: LogsDedupDescription[LogsDedupStrategy.signature],
          },
        ],
      },
      defaultValue: LogsDedupStrategy.none,
    })
    .addRadio({
      path: 'sortOrder',
      name: '並び順',
      description: '',
      settings: {
        options: [
          { value: LogsSortOrder.Descending, label: '降順' },
          { value: LogsSortOrder.Ascending, label: '昇順' },
        ],
      },
      defaultValue: LogsSortOrder.Descending,
    });
});
