import React, { ChangeEvent } from 'react';
import {
  DataTransformerID,
  SelectableValue,
  standardTransformers,
  TransformerRegistryItem,
  TransformerUIProps,
} from '@grafana/data';
import { Input, Select } from '@grafana/ui';
import {
  ConcatenateFrameNameMode,
  ConcatenateTransformerOptions,
} from '@grafana/data/src/transformations/transformers/concat';

interface ConcatenateTransformerEditorProps extends TransformerUIProps<ConcatenateTransformerOptions> {}

const nameModes: Array<SelectableValue<ConcatenateFrameNameMode>> = [
  { value: ConcatenateFrameNameMode.FieldName, label: 'フレーム名をフィールド名にコピー' },
  { value: ConcatenateFrameNameMode.Label, label: 'フレーム名のラベルを追加' },
  { value: ConcatenateFrameNameMode.Drop, label: 'フレーム名を無視' },
];

export class ConcatenateTransformerEditor extends React.PureComponent<ConcatenateTransformerEditorProps> {
  constructor(props: ConcatenateTransformerEditorProps) {
    super(props);
  }

  onModeChanged = (value: SelectableValue<ConcatenateFrameNameMode>) => {
    const { options, onChange } = this.props;
    const frameNameMode = value.value ?? ConcatenateFrameNameMode.FieldName;
    onChange({
      ...options,
      frameNameMode,
    });
  };

  onLabelChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    const { options } = this.props;
    this.props.onChange({
      ...options,
      frameNameLabel: evt.target.value,
    });
  };

  //---------------------------------------------------------
  // Render
  //---------------------------------------------------------

  render() {
    const { options } = this.props;

    const frameNameMode = options.frameNameMode ?? ConcatenateFrameNameMode.FieldName;

    return (
      <div>
        <div className="gf-form-inline">
          <div className="gf-form">
            <div className="gf-form-label width-8">名前</div>
            <Select
              className="width-18"
              options={nameModes}
              value={nameModes.find((v) => v.value === frameNameMode)}
              onChange={this.onModeChanged}
            />
          </div>
        </div>
        {frameNameMode === ConcatenateFrameNameMode.Label && (
          <div className="gf-form-inline">
            <div className="gf-form">
              <div className="gf-form-label width-8">ラベル</div>
              <Input
                className="width-18"
                value={options.frameNameLabel ?? ''}
                placeholder="フレーム"
                onChange={this.onLabelChanged}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const concatenateTransformRegistryItem: TransformerRegistryItem<ConcatenateTransformerOptions> = {
  id: DataTransformerID.concatenate,
  editor: ConcatenateTransformerEditor,
  transformation: standardTransformers.concatenateTransformer,
  name: 'フィールドを連結',
  description: 'すべてのフィールドを1つのフレームに結合します。 同じ長さでない場合、値には未定義の値が追加されます。',
};
