import React from 'react';
import { ConfirmModal } from '@grafana/ui';
import { useDashboardRestore } from './useDashboardRestore';
export interface RevertDashboardModalProps {
  hideModal: () => void;
  version: number;
}

export const RevertDashboardModal: React.FC<RevertDashboardModalProps> = ({ hideModal, version }) => {
  // TODO: how should state.error be handled?
  const { onRestoreDashboard } = useDashboardRestore(version);

  return (
    <ConfirmModal
      isOpen={true}
      title="版数復元"
      icon="history"
      onDismiss={hideModal}
      onConfirm={onRestoreDashboard}
      body={
        <p>
          ダッシュボードをバージョン「{version}」に復元してもよろしいですか？保存されていない変更はすべて失われます。
        </p>
      }
      confirmText={`はい。復元版数：${version}`}
    />
  );
};
