import React from 'react';

const PermissionsInfo = () => (
  <div>
    <h5>アクセス権限とは?</h5>
    <p>
      アクセス制御リスト(ACL)モデルはダッシュボード・フォルダーへのアクセスを制限するために使用します。
      ユーザーまたはチームにはフォルダーや単一のダッシュボードに対し権限を割り当てることができます。
    </p>
  </div>
);

export default PermissionsInfo;
