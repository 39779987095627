import React from 'react';
import { useTheme } from '../../themes/ThemeContext';
import { getSelectStyles } from './getSelectStyles';
import { cx } from 'emotion';
import { SelectableValue } from '@grafana/data';
import { Icon } from '../Icon/Icon';
import { CustomScrollbar } from '../CustomScrollbar/CustomScrollbar';

interface SelectMenuProps {
  maxHeight: number;
  innerRef: React.Ref<any>;
  innerProps: {};
}

export const SelectMenu = React.forwardRef<HTMLDivElement, React.PropsWithChildren<SelectMenuProps>>((props, ref) => {
  const theme = useTheme();
  const styles = getSelectStyles(theme);
  const { children, maxHeight, innerRef, innerProps } = props;

  return (
    <div {...innerProps} className={styles.menu} ref={innerRef} style={{ maxHeight }} aria-label="Select options menu">
      <CustomScrollbar autoHide={false} autoHeightMax="inherit" hideHorizontalTrack>
        {children}
      </CustomScrollbar>
    </div>
  );
});

SelectMenu.displayName = 'SelectMenu';

interface SelectMenuOptionProps<T> {
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
  innerProps: any;
  renderOptionLabel?: (value: SelectableValue<T>) => JSX.Element;
  data: SelectableValue<T>;
}

export const SelectMenuOptions = React.forwardRef<HTMLDivElement, React.PropsWithChildren<SelectMenuOptionProps<any>>>(
  (props, ref) => {
    const theme = useTheme();
    const styles = getSelectStyles(theme);
    const { children, innerProps, data, renderOptionLabel, isSelected, isFocused } = props;

    let descStr = data.description;
    if (descStr) {
      for (let i = 0; i < descReducers.length; i++) {
        descStr = descStr.replace(descReducers[i].en, descReducers[i].jp);
      }
    }

    return (
      <div
        ref={ref}
        className={cx(styles.option, isFocused && styles.optionFocused)}
        {...innerProps}
        aria-label="Select option"
      >
        {data.imgUrl && <img className={styles.optionImage} src={data.imgUrl} />}
        <div className={styles.optionBody}>
          <span>{renderOptionLabel ? renderOptionLabel(data) : children}</span>
          {data.description && <div className={styles.optionDescription}>{descStr}</div>}
          {data.component && <data.component />}
        </div>
        {isSelected && (
          <span>
            <Icon name="check" />
          </span>
        )}
      </div>
    );
  }
);

SelectMenuOptions.displayName = 'SelectMenuOptions';

export const descReducers = [
  {
    en: 'Invalid username or password',
    jp: 'アカウントまたはパスワードが違います。',
  },
  {
    en: 'invalid username or password',
    jp: 'アカウントまたはパスワードが違います。',
  },
  {
    en: 'Sends HTTP POST request to DingDing',
    jp: 'HTTP POST リクエストを DingDing に送信します',
  },
  {
    en: 'Sends notifications to Discord',
    jp: 'Discord に通知を送信します',
  },
  {
    en: 'Sends notifications using Grafana server configured SMTP settings',
    jp: 'Grafana サーバーで構成された SMTP設定を使用して通知を送信します',
  },
  {
    en: 'Sends notifications to Google Hangouts Chat via webhooks based on the official JSON message format',
    jp: '公式のJSONメッセージ形式に基づいて、Webhook経由で Google Hangouts Chat に通知を送信します',
  },
  {
    en: 'Sends notifications uto a HipChat Room',
    jp: 'HipChat ルームに通知を送信します',
  },
  {
    en: 'Sends notifications to Kafka Rest Proxy',
    jp: 'Kafka Rest Proxy に通知を送信します',
  },
  {
    en: 'Send notifications to LINE notify',
    jp: 'LINE notify に通知を送信します',
  },
  {
    en: 'Sends notifications using Incoming Webhook connector to Microsoft Teams',
    jp: 'Incoming Webhook コネクタを使用して通知を Microsoft Teams に送信します',
  },
  {
    en: 'Sends notifications to OpsGenie',
    jp: 'OpsGenie に通知を送信します',
  },
  {
    en: 'Sends notifications to PagerDuty',
    jp: 'PagerDuty に通知を送信します',
  },
  {
    en: 'Sends alert to Prometheus Alertmanager',
    jp: 'Prometheus Alertmanager にアラートを送信します',
  },
  {
    en: 'Sends HTTP POST request to the Pushover API',
    jp: 'HTTP POSTリクエストを Pushover API に送信します',
  },
  {
    en: 'Sends HTTP POST request to a Sensu API',
    jp: 'HTTP POST リクエストを Sensu API に送信します',
  },
  {
    en: 'Sends HTTP POST request to a Sensu Go API',
    jp: 'HTTP POST リクエストを Sensu Go API に送信します',
  },
  {
    en: 'Sends notifications to Slack via Slack Webhooks',
    jp: 'Slack Webhook 経由で Slack に通知を送信します',
  },
  {
    en: 'Sends notifications to Telegram',
    jp: 'Telegram に通知を送信します',
  },
  {
    en: 'Sends notifications to Threema using the Threema Gateway',
    jp: 'Threema Gateway を使用して Threema に通知を送信します',
  },
  {
    en: 'Sends notifications to VictorOps',
    jp: 'VictorOps に通知を送信します',
  },
  {
    en: 'Sends HTTP POST request to a URL',
    jp: 'HTTP POST リクエストを URL に送信します',
  },
];
