import React, { FormEvent, PureComponent } from 'react';
import { RadioButtonGroup, Switch, Field, TextArea, ClipboardButton } from '@grafana/ui';
import { SelectableValue, AppEvents } from '@grafana/data';
import { DashboardModel, PanelModel } from 'app/features/dashboard/state';
import { appEvents } from 'app/core/core';
import { buildIframeHtml } from './utils';

const themeOptions: Array<SelectableValue<string>> = [
  { label: 'Current', value: 'current' },
  { label: 'Dark', value: 'dark' },
  { label: 'Light', value: 'light' },
];

interface Props {
  dashboard: DashboardModel;
  panel?: PanelModel;
}

interface State {
  useCurrentTimeRange: boolean;
  selectedTheme: string;
  iframeHtml: string;
}

export class ShareEmbed extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      useCurrentTimeRange: true,
      selectedTheme: 'current',
      iframeHtml: '',
    };
  }

  componentDidMount() {
    this.buildIframeHtml();
  }

  buildIframeHtml = () => {
    const { panel } = this.props;
    const { useCurrentTimeRange, selectedTheme } = this.state;

    const iframeHtml = buildIframeHtml(useCurrentTimeRange, selectedTheme, panel);
    this.setState({ iframeHtml });
  };

  onIframeHtmlChange = (event: FormEvent<HTMLTextAreaElement>) => {
    this.setState({ iframeHtml: event.currentTarget.value });
  };

  onUseCurrentTimeRangeChange = () => {
    this.setState(
      {
        useCurrentTimeRange: !this.state.useCurrentTimeRange,
      },
      this.buildIframeHtml
    );
  };

  onThemeChange = (value: string) => {
    this.setState({ selectedTheme: value }, this.buildIframeHtml);
  };

  onIframeHtmlCopy = () => {
    appEvents.emit(AppEvents.alertSuccess, ['クリップボードにコピーしました。']);
  };

  getIframeHtml = () => {
    return this.state.iframeHtml;
  };

  render() {
    const { useCurrentTimeRange, selectedTheme, iframeHtml } = this.state;
    const isRelativeTime = this.props.dashboard ? this.props.dashboard.time.to === 'now' : false;

    return (
      <div className="share-modal-body">
        <div className="share-modal-header">
          <div className="share-modal-content">
            <p className="share-modal-info-text">このパネルでiframeを埋め込むHTMLを生成します。</p>
            <Field
              label="現在の時間範囲"
              description={isRelativeTime ? '現在の相対時間範囲を絶対時間範囲に変換します' : ''}
            >
              <Switch
                id="share-current-time-range"
                value={useCurrentTimeRange}
                onChange={this.onUseCurrentTimeRangeChange}
              />
            </Field>
            <Field label="テーマ">
              <RadioButtonGroup options={themeOptions} value={selectedTheme} onChange={this.onThemeChange} />
            </Field>
            <Field
              label="組込HTML"
              description="以下のhtmlコードを貼り付けて、別のWebページに含めることができます。匿名アクセスが有効になっていない限り、
                そのページを表示しているユーザーはグラフをロードするためにgrafanaにサインインする必要があります。"
            >
              <TextArea rows={5} value={iframeHtml} onChange={this.onIframeHtmlChange}></TextArea>
            </Field>
            <ClipboardButton variant="primary" getText={this.getIframeHtml} onClipboardCopy={this.onIframeHtmlCopy}>
              クリップボードにコピー
            </ClipboardButton>
          </div>
        </div>
      </div>
    );
  }
}
