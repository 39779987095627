import React, { PureComponent } from 'react';
import { renderMarkdown } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';

export class LoginNews extends PureComponent {
  state = {
    isError: false,
    isLoading: false,
    news: '',
  };

  componentDidMount(): void {
    this.loadNews();
  }

  constructPlaceholderInfo() {
    return 'お知らせはありません';
  }

  loadNews = () => {
    this.setState({ isLoading: true });
    const date = new Date();
    const dateStr = date
      .toISOString()
      .replace(/[^0-9]/g, '')
      .slice(0, -5);
    const url = '/public/help/news.html?d=' + dateStr;

    getBackendSrv()
      .get(url)
      .then((response: string) => {
        const newsHtml = renderMarkdown(response);

        if (response === '' || newsHtml.startsWith('<!DOCTYPE html>')) {
          this.setState({
            isError: false,
            isLoading: false,
            news: this.constructPlaceholderInfo(),
          });
        } else {
          this.setState({
            isError: false,
            isLoading: false,
            news: newsHtml,
          });
        }
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  render() {
    const { isError, isLoading, news } = this.state;

    if (isLoading) {
      return 'お知らせ読込中...';
    }

    if (isError) {
      return <h3>&apos;お知らせ読込中にエラーが発生しました&apos;</h3>;
    }

    return <div className="markdown-html loginNews" dangerouslySetInnerHTML={{ __html: news }} />;
  }
}
