import React, { useCallback } from 'react';
import { css } from 'emotion';
import { GrafanaTheme, SelectableValue, TransformerRegistryItem, TransformerUIProps } from '@grafana/data';
import { prepareTimeSeriesTransformer, PrepareTimeSeriesOptions, timeSeriesFormat } from './prepareTimeSeries';
import { InlineField, InlineFieldRow, Select, useStyles } from '@grafana/ui';

const wideInfo = {
  label: 'Wide time series',
  value: timeSeriesFormat.TimeSeriesWide,
  description: '時間で結合された単一のフレームを作成',
  info: (
    <ul>
      <li>単一フレーム</li>
      <li>最初のフィールドは共有された時間フィールド</li>
      <li>時間で昇順</li>
      <li>任意のタイプの複数の値フィールド</li>
    </ul>
  ),
};

const manyInfo = {
  label: 'Multi-frame time series',
  value: timeSeriesFormat.TimeSeriesMany,
  description: '時間/数値のペアごとに新しいフレームを作成',
  info: (
    <ul>
      <li>マルチフレーム</li>
      <li>各フレームは時間と値の2つのフィールドを持つ</li>
      <li>時間で昇順</li>
      <li>全ての値は数値</li>
    </ul>
  ),
};

const formats: Array<SelectableValue<timeSeriesFormat>> = [wideInfo, manyInfo];

export function PrepareTimeSeriesEditor(props: TransformerUIProps<PrepareTimeSeriesOptions>): React.ReactElement {
  const { options, onChange } = props;
  const styles = useStyles(getStyles);

  const onSelectFormat = useCallback(
    (value: SelectableValue<timeSeriesFormat>) => {
      onChange({
        ...options,
        format: value.value!,
      });
    },
    [onChange, options]
  );

  return (
    <>
      <InlineFieldRow>
        <InlineField label="フォーマット" labelWidth={12}>
          <Select
            width={35}
            options={formats}
            value={formats.find((v) => v.value === options.format) || formats[0]}
            onChange={onSelectFormat}
            className="flex-grow-1"
          />
        </InlineField>
      </InlineFieldRow>
      <InlineFieldRow>
        <InlineField label="情報" labelWidth={12}>
          <div className={styles.info}>
            {options.format === timeSeriesFormat.TimeSeriesMany ? manyInfo.info : wideInfo.info}
          </div>
        </InlineField>
      </InlineFieldRow>
    </>
  );
}

const getStyles = (theme: GrafanaTheme) => ({
  info: css`
    margin-left: 20px;
  `,
});

export const prepareTimeseriesTransformerRegistryItem: TransformerRegistryItem<PrepareTimeSeriesOptions> = {
  id: prepareTimeSeriesTransformer.id,
  editor: PrepareTimeSeriesEditor,
  transformation: prepareTimeSeriesTransformer,
  name: prepareTimeSeriesTransformer.name,
  description: prepareTimeSeriesTransformer.description,
};
