import { SingleStatBaseOptions, BarGaugeDisplayMode } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';

export interface BarGaugeOptions extends SingleStatBaseOptions {
  displayMode: BarGaugeDisplayMode;
  showUnfilled: boolean;
}

export const displayModes: Array<SelectableValue<string>> = [
  { value: BarGaugeDisplayMode.Gradient, label: 'グラデーション' },
  { value: BarGaugeDisplayMode.Lcd, label: 'レトロなLCD' },
  { value: BarGaugeDisplayMode.Basic, label: '基本(単色)' },
];
