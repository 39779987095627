import { PanelPlugin } from '@grafana/data';
import { TablePanel } from './TablePanel';
import { CustomFieldConfig, Options } from './types';
import { tableMigrationHandler, tablePanelChangedHandler } from './migrations';
import { TableCellDisplayMode } from '@grafana/ui';

export const plugin = new PanelPlugin<Options, CustomFieldConfig>(TablePanel)
  .setPanelChangeHandler(tablePanelChangedHandler)
  .setMigrationHandler(tableMigrationHandler)
  .setNoPadding()
  .useFieldConfig({
    useCustomConfig: (builder) => {
      builder
        .addNumberInput({
          path: 'width',
          name: '列幅',
          settings: {
            placeholder: 'auto',
            min: 20,
            max: 300,
          },
          shouldApply: () => true,
        })
        .addRadio({
          path: 'align',
          name: '列配置',
          settings: {
            options: [
              { label: '自動', value: null },
              { label: '左寄せ', value: 'left' },
              { label: '中央寄せ', value: 'center' },
              { label: '右寄せ', value: 'right' },
            ],
          },
          defaultValue: null,
        })
        .addSelect({
          path: 'displayMode',
          name: 'セル表示モード',
          description: '文字色、背景色、ゲージとして表示など',
          settings: {
            options: [
              { value: TableCellDisplayMode.Auto, label: '自動(装飾なし)' },
              { value: TableCellDisplayMode.ColorText, label: '文字色で状態を表示' },
              { value: TableCellDisplayMode.ColorBackground, label: '背景色で状態を表示' },
              { value: TableCellDisplayMode.GradientGauge, label: 'グラデージョンゲージで表示' },
              { value: TableCellDisplayMode.LcdGauge, label: 'LCDゲージで表示' },
              { value: TableCellDisplayMode.BasicGauge, label: 'Basicゲージで表示' },
              { value: TableCellDisplayMode.JSONView, label: 'JSON表示' },
              { value: TableCellDisplayMode.Image, label: 'イメージ' },
            ],
          },
        })
        .addBooleanSwitch({
          path: 'filterable',
          name: '列フィルター',
          description: 'フィールドフィルターの有効/無効',
          defaultValue: false,
        });
    },
  })
  .setPanelOptions((builder) => {
    builder.addBooleanSwitch({
      path: 'showHeader',
      name: 'タイトル行を表示',
      description: 'タイトル行(表見出し)を表示するか否かを指定します。',
      defaultValue: true,
    });
  });
