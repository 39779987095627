import React from 'react';
import { appEvents } from 'app/core/core';
import { Icon } from '@grafana/ui';
import { LoginNews } from '../Login/LoginNews';

export class NewsModal extends React.PureComponent {
  dismiss() {
    appEvents.emit('hide-modal');
  }

  render() {
    return (
      <div className="modal-body">
        <div className="modal-header">
          <h2 className="modal-header-title">
            <Icon name="document-info" size="lg" />
            <span className="p-l-1">お知らせ</span>
          </h2>
          <a className="modal-header-close" onClick={this.dismiss}>
            <Icon name="times" style={{ margin: '3px 0 0 0' }} />
          </a>
        </div>

        <div className="modal-content news-modal">
          <LoginNews />
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}
