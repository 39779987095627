import React, { PropsWithChildren, useMemo } from 'react';
import { SelectableValue } from '@grafana/data';
import { selectors } from '@grafana/e2e-selectors';
import { VariableSelectField } from '../editor/VariableSelectField';
import { VariableSort } from '../types';

interface Props {
  onChange: (option: SelectableValue<VariableSort>) => void;
  sort: VariableSort;
}

const SORT_OPTIONS = [
  { label: '無効', value: VariableSort.disabled },
  { label: 'アルファベット(昇順)', value: VariableSort.alphabeticalAsc },
  { label: 'アルファベット(降順)', value: VariableSort.alphabeticalDesc },
  { label: '数値(昇順)', value: VariableSort.numericalAsc },
  { label: '数値(降順)', value: VariableSort.numericalDesc },
  { label: 'アルファベット(大文字小文字区別なし, 昇順)', value: VariableSort.alphabeticalCaseInsensitiveAsc },
  { label: 'アルファベット(大文字小文字区別なし, 降順', value: VariableSort.alphabeticalCaseInsensitiveDesc },
];

export function QueryVariableSortSelect({ onChange, sort }: PropsWithChildren<Props>) {
  const value = useMemo(() => SORT_OPTIONS.find((o) => o.value === sort) ?? SORT_OPTIONS[0], [sort]);

  return (
    <VariableSelectField
      name="並び替え"
      value={value}
      options={SORT_OPTIONS}
      onChange={onChange}
      labelWidth={10}
      ariaLabel={selectors.pages.Dashboard.Settings.Variables.Edit.QueryVariable.queryOptionsSortSelect}
      tooltip="この変数値を並べ替える方法"
    />
  );
}
