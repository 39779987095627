import { FieldColorModeId, FieldConfigProperty, PanelPlugin } from '@grafana/data';
import { PieChartPanel } from './PieChartPanel';
import { PieChartOptions } from './types';
import { addStandardDataReduceOptions } from '../stat/types';
import { LegendDisplayMode, PieChartType, PieChartLabels, PieChartLegendValues } from '@grafana/ui';
import { PieChartPanelChangedHandler } from './migrations';

export const plugin = new PanelPlugin<PieChartOptions>(PieChartPanel)
  .setPanelChangeHandler(PieChartPanelChangedHandler)
  .useFieldConfig({
    standardOptions: {
      [FieldConfigProperty.Color]: {
        settings: {
          byValueSupport: false,
          bySeriesSupport: true,
          preferThresholdsMode: false,
        },
        defaultValue: {
          mode: FieldColorModeId.PaletteClassic,
        },
      },
    },
  })
  .setPanelOptions((builder) => {
    addStandardDataReduceOptions(builder, false);

    builder
      .addRadio({
        name: '円グラフ種類',
        description: '円グラフの描画方法',
        path: 'pieType',
        settings: {
          options: [
            { value: PieChartType.Pie, label: '円グラフ' },
            { value: PieChartType.Donut, label: 'ドーナツ' },
          ],
        },
        defaultValue: PieChartType.Pie,
      })
      .addMultiSelect({
        name: 'ラベル',
        path: 'displayLabels',
        description: '円グラフに表示するラベルを選択します',
        settings: {
          options: [
            { value: PieChartLabels.Percent, label: 'パーセント' },
            { value: PieChartLabels.Name, label: '名前' },
            { value: PieChartLabels.Value, label: '値' },
          ],
        },
      })
      .addRadio({
        path: 'legend.displayMode',
        name: '凡例モード',
        description: '',
        defaultValue: LegendDisplayMode.List,
        settings: {
          options: [
            { value: LegendDisplayMode.List, label: 'リスト' },
            { value: LegendDisplayMode.Table, label: '一覧' },
            { value: LegendDisplayMode.Hidden, label: '表示' },
          ],
        },
      })
      .addRadio({
        path: 'legend.placement',
        name: '凡例の配置',
        description: '',
        defaultValue: 'right',
        settings: {
          options: [
            { value: 'bottom', label: '下' },
            { value: 'right', label: '右' },
          ],
        },
        showIf: (c) => c.legend.displayMode !== LegendDisplayMode.Hidden,
      })
      .addMultiSelect({
        name: '凡例の値',
        path: 'legend.values',
        settings: {
          options: [
            { value: PieChartLegendValues.Percent, label: 'パーセント' },
            { value: PieChartLegendValues.Value, label: '値' },
          ],
        },
        showIf: (c) => c.legend.displayMode !== LegendDisplayMode.Hidden,
      });
  });
