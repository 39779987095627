import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { css } from 'emotion';
import { GrafanaTheme } from '@grafana/data';
import { selectors } from '@grafana/e2e-selectors';
import { useTheme } from '../../themes';
import { Icon } from '../Icon/Icon';
import { IconName } from '../../types/icon';
import { getColorsFromSeverity } from '../../utils/colors';

export type AlertVariant = 'success' | 'warning' | 'error' | 'info';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  title: string;
  /** On click handler for alert button, mostly used for dismissing the alert */
  onRemove?: (event: React.MouseEvent) => void;
  severity?: AlertVariant;
  children?: ReactNode;
  /** Custom component or text for alert button */
  buttonContent?: ReactNode | string;
  /** @deprecated */
  /** Deprecated use onRemove instead */
  onButtonClick?: (event: React.MouseEvent) => void;
  /** @deprecated */
  /** Deprecated use buttonContent instead */
  buttonText?: string;
}

function getIconFromSeverity(severity: AlertVariant): string {
  switch (severity) {
    case 'error':
    case 'warning':
      return 'exclamation-triangle';
    case 'info':
      return 'info-circle';
    case 'success':
      return 'check';
    default:
      return '';
  }
}

export const Alert: FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ title, buttonText, onButtonClick, onRemove, children, buttonContent, severity = 'error', ...restProps }, ref) => {
    const theme = useTheme();
    const styles = getStyles(theme, severity, !!buttonContent);

    let titleStr = title;
    for (let i = 0; i < alertReducers.length; i++) {
      titleStr = titleStr.replace(alertReducers[i].en, alertReducers[i].jp);
    }
    titleStr = titleStr.replace(/^User with email .* or username .* already exists$/, function (match) {
      let rtn = match.replace('User with email', 'メールアドレス');
      rtn = rtn.replace('or username', 'またはアカウント');
      rtn = rtn.replace('already exists', 'が既に使われています。');
      return rtn;
    });
    titleStr = titleStr.replace(/^Folder .* deleted$/, function (match) {
      let rtn = 'フォルダー' + match.replace(/^Folder/, '').replace(/ deleted$$/, '') + 'を削除しました。';
      return rtn;
    });

    return (
      <div ref={ref} className={styles.alert} aria-label={selectors.components.Alert.alert(severity)} {...restProps}>
        <div className={styles.icon}>
          <Icon size="xl" name={getIconFromSeverity(severity) as IconName} />
        </div>
        <div className={styles.body}>
          <div className={styles.title}>{titleStr}</div>
          {children && <div>{children}</div>}
        </div>
        {/* If onRemove is specified, giving preference to onRemove */}
        {onRemove ? (
          <button type="button" className={styles.close} onClick={onRemove}>
            {buttonContent || <Icon name="times" size="lg" />}
          </button>
        ) : onButtonClick ? (
          <button type="button" className="btn btn-outline-danger" onClick={onButtonClick}>
            {buttonText}
          </button>
        ) : null}
      </div>
    );
  }
);

Alert.displayName = 'Alert';

const getStyles = (theme: GrafanaTheme, severity: AlertVariant, outline: boolean) => {
  const { white } = theme.palette;
  const severityColors = getColorsFromSeverity(severity, theme);
  const background = css`
    background: linear-gradient(90deg, ${severityColors[0]}, ${severityColors[0]});
  `;

  return {
    alert: css`
      flex-grow: 1;
      padding: 15px 20px;
      margin-bottom: ${theme.spacing.xs};
      position: relative;
      color: ${white};
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
      border-radius: ${theme.border.radius.md};
      display: flex;
      flex-direction: row;
      align-items: center;
      ${background}
    `,
    icon: css`
      padding: 0 ${theme.spacing.md} 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
    `,
    title: css`
      font-weight: ${theme.typography.weight.semibold};
    `,
    body: css`
      flex-grow: 1;
      margin: 0 ${theme.spacing.md} 0 0;
      overflow-wrap: break-word;
      word-break: break-word;

      a {
        color: ${white};
        text-decoration: underline;
      }
    `,
    close: css`
      background: none;
      display: flex;
      align-items: center;
      border: ${outline ? `1px solid ${white}` : 'none'};
      border-radius: ${theme.border.radius.sm};
    `,
  };
};

export const alertReducers = [
  {
    en: 'Invalid username or password',
    jp: 'アカウントまたはパスワードが違います。',
  },
  {
    en: 'invalid username or password',
    jp: 'アカウントまたはパスワードが違います。',
  },
  {
    en: 'Failed to get admin stats from database',
    jp: 'データベースから管理統計を取得できません。',
  },
  {
    en: 'Dashboards config reloaded',
    jp: 'ダッシュボード設定を再読込しました。',
  },
  {
    en: 'Datasources config reloaded',
    jp: 'データソース設定を再読込しました。',
  },
  {
    en: 'Failed to reload plugins config',
    jp: 'プラグイン設定の再読込に失敗しました。',
  },
  {
    en: 'Plugins config reloaded',
    jp: 'プラグイン設定を再読込しました。',
  },
  {
    en: 'Notifications config reloaded',
    jp: '通知設定を再読込しました。',
  },
  {
    en: 'Validation error, need specify either username or email',
    jp: 'アカウントまたはメールアドレスを指定する必要があります。',
  },
  {
    en: 'Password is missing or too short',
    jp: 'パスワードがないか、短すぎます。',
  },
  {
    en: 'failed to create user',
    jp: 'ユーザーの作成に失敗しました。',
  },
  {
    en: 'New password too short',
    jp: '新しいパスワードが短すぎます。',
  },
  {
    en: 'Could not read user from database',
    jp: 'データベースからユーザー情報を取得できません。',
  },
  {
    en: 'Could not encode password',
    jp: 'パスワードの暗号化に失敗しました。',
  },
  {
    en: 'Failed to update user password',
    jp: 'パスワードの更新に失敗しました。',
  },
  {
    en: 'User password updated',
    jp: 'パスワードを更新しました。',
  },
  {
    en: 'Failed to update user permissions',
    jp: 'ユーザー権限の更新に失敗しました。',
  },
  {
    en: 'User permissions updated',
    jp: 'ユーザー権限を更新しました。',
  },
  {
    en: 'Failed to delete user',
    jp: 'ユーザーの削除に失敗しました。',
  },
  {
    en: 'User deleted',
    jp: 'ユーザーを削除しました。',
  },
  {
    en: 'Could not disable external user',
    jp: '外部ユーザーは無効化できません。',
  },
  {
    en: 'Failed to disable user',
    jp: 'ユーザーの無効化に失敗しました。',
  },
  {
    en: 'User disabled',
    jp: 'ユーザーを無効化しました。',
  },
  {
    en: 'Could not enable external user',
    jp: '外部ユーザーは有効化できません。',
  },
  {
    en: 'Failed to enable user',
    jp: 'ユーザーの有効化に失敗しました。',
  },
  {
    en: 'User enabled',
    jp: 'ユーザーを有効化しました。',
  },
  {
    en: 'You cannot logout yourself',
    jp: 'ログアウトできません。',
  },
  {
    en: 'Missing query parameter dashboardId',
    jp: 'クエリーパラメータ dashboardId がありません。',
  },
  {
    en: 'Failed to fetch alert states',
    jp: 'アラート統計の取得に失敗しました。',
  },
  {
    en: 'List alerts failed',
    jp: 'アラート表示に失敗しました。',
  },
  {
    en: 'The dashboard needs to be saved at least once before you can test an alert rule',
    jp: 'アラートルールのテスト前に、ダッシュボードを保存してください。',
  },
  {
    en: 'Access denied to datasource',
    jp: 'データソースへのアクセスが拒否されました。',
  },
  {
    en: 'Failed to test rule',
    jp: 'ルールのテストに失敗しました。',
  },
  {
    en: 'Failed to get alert notifications',
    jp: 'アラート通知の取得に失敗しました。',
  },
  {
    en: 'Alert notification not found',
    jp: 'アラート通知が見つかりません。',
  },
  {
    en: 'alert notification not found',
    jp: 'アラート通知が見つかりません。',
  },
  {
    en: 'Failed to create alert notification',
    jp: 'アラート通知の作成に失敗しました。',
  },
  {
    en: 'Failed to update alert notification',
    jp: 'アラート通知の更新に失敗しました。',
  },
  {
    en: 'Failed to delete alert notification',
    jp: 'アラート通知の削除に失敗しました。',
  },
  {
    en: 'Failed to send alert notifications',
    jp: 'アラート通知の送信に失敗しました。',
  },
  {
    en: 'Test notification sent',
    jp: 'テスト通知を送信しました。',
  },
  {
    en: 'Get Alert failed',
    jp: 'アラートの取得に失敗しました。',
  },
  {
    en: 'Error while checking permissions for Alert',
    jp: 'アラートの権限確認中にエラーが発生しました。',
  },
  {
    en: 'Access denied to this dashboard and alert',
    jp: 'ダッシュボードとアラートへのアクセスが拒否されました。',
  },
  {
    en: 'Failed to pause alerts',
    jp: 'アラートの一時停止に失敗しました。',
  },
  {
    en: 'Failed to get annotations',
    jp: '注釈の取得に失敗しました。',
  },
  {
    en: 'Failed to save annotation',
    jp: '注釈の保存に失敗しました。',
  },
  {
    en: 'Failed to save Graphite annotation',
    jp: 'Graphite 注釈の保存に失敗しました。',
  },
  {
    en: 'Failed to update annotation',
    jp: '注釈の更新に失敗しました。',
  },
  {
    en: 'Annotation updated',
    jp: '注釈を更新しました。',
  },
  {
    en: 'Could not find annotation to update',
    jp: '更新する注釈が見つかりません。',
  },
  {
    en: 'Annotation patched',
    jp: '注釈にパッチを適用しました。',
  },
  {
    en: 'Failed to delete annotations',
    jp: '注釈の削除に失敗しました。',
  },
  {
    en: 'Annotations deleted',
    jp: '注釈を削除しました。',
  },
  {
    en: 'Annotation deleted',
    jp: '注釈を削除しました。',
  },
  {
    en: 'Annotation added',
    jp: '注釈を追加しました。',
  },
  {
    en: 'Failed to list api keys',
    jp: 'APIキーの表示に失敗しました。',
  },
  {
    en: 'Failed to delete API key',
    jp: 'APIキーの削除に失敗しました。',
  },
  {
    en: 'API key deleted',
    jp: 'APIキーを削除しました。',
  },
  {
    en: 'Invalid role specified',
    jp: '無効なロールが指定されました。',
  },
  {
    en: 'Number of seconds before expiration should be set',
    jp: '有効期限切れまでの秒数を設定してください。',
  },
  {
    en: 'Number of seconds before expiration is greater than the global limit',
    jp: '有効期限切れまでの秒数がグローバル制限を超えています。',
  },
  {
    en: 'Generating API key failed',
    jp: 'APIキーの生成に失敗しました。',
  },
  {
    en: 'Failed to add API Key',
    jp: 'APIキーの追加に失敗しました。',
  },
  {
    en: 'Error while checking dashboard permissions',
    jp: 'ダッシュボード権限の確認中にエラーが発生しました。',
  },
  {
    en: 'Access denied to this dashboard',
    jp: 'このダッシュボードへのアクセスは拒否されました。',
  },
  {
    en: 'Error while loading dashboard, dashboard data is invalid',
    jp: 'ダッシュボードの読込中にエラーが発生しました。ダッシュボードデータが無効です。',
  },
  {
    en: 'Error while checking if dashboard was starred by user',
    jp: 'ダッシュボードのお気に入り確認中にエラーが発生しました。',
  },
  {
    en: 'Dashboard folder could not be read',
    jp: 'ダッシュボード・フォルダーが読込できません。',
  },
  {
    en: 'Error while checking if dashboard is provisioned',
    jp: 'ダッシュボードのプロビジョニング確認中にエラーが発生しました。',
  },
  {
    en: 'Error while loading library panels',
    jp: 'ライブラリ・パネルの読込中にエラーが発生しました。',
  },
  {
    en: 'Failed to retrieve dashboards by slug',
    jp: 'スラッグによるダッシュボード取得に失敗しました。',
  },
  {
    en: 'failed to get quota',
    jp: 'クォータの取得に失敗しました。',
  },
  {
    en: 'Quota reached',
    jp: 'クォータに達しました。',
  },
  {
    en: 'Error while cleaning library panels',
    jp: 'ライブラリ・パネルのクリーニング中にエラーが発生しました。',
  },
  {
    en: 'Error while connecting library panels',
    jp: 'ライブラリ・パネルの接続中にエラーが発生しました。',
  },
  {
    en: 'Failed to save dashboard',
    jp: 'ダッシュボードの保存に失敗しました。',
  },
  {
    en: 'Failed to get preferences',
    jp: '環境設定の取得に失敗しました。',
  },
  {
    en: 'Failed to load home dashboard',
    jp: 'ホームダッシュボードの取得に失敗しました。',
  },
  {
    en: 'No versions found for dashboardId',
    jp: 'ダッシュボード版数が見つかりません。ダッシュボードID:',
  },
  {
    en: 'Dashboard not found',
    jp: 'ダッシュボードが見つかりません。 ',
  },
  {
    en: 'Dashboard version not found',
    jp: 'ダッシュボード版数が見つかりません。 ',
  },
  {
    en: 'dashboard version not found',
    jp: 'ダッシュボード版数が見つかりません。 ',
  },
  {
    en: 'Dashboard version',
    jp: 'ダッシュボード版数',
  },
  {
    en: 'not found for dashboardId',
    jp: 'が見つかりません。ダッシュボードID:',
  },
  {
    en: 'Unable to compute diff',
    jp: '差分を計算できません.',
  },
  {
    en: 'Failed to get dashboard permissions',
    jp: 'ダッシュボード権限の取得に失敗しました。',
  },
  {
    en: 'A dashboard with the same uid already exists',
    jp: '同じuidのダッシュボードが既に存在します。',
  },
  {
    en: 'A dashboard with the same name in the folder already exists',
    jp: '同じ名前のダッシュボードがフォルダーに既に存在します。',
  },
  {
    en: 'The dashboard has been changed by someone else',
    jp: '他の人によってダッシュボードが変更されています。',
  },
  {
    en: 'The folder has been changed by someone else',
    jp: '他の人によってフォルダーが変更されています。',
  },
  {
    en: 'the folder has been changed by someone else',
    jp: '他の人によってフォルダーが変更されています。',
  },
  {
    en: 'Dashboard title cannot be empty',
    jp: 'ダッシュボード名は空にできません。',
  },
  {
    en: 'A Dashboard Folder cannot be added to another folder',
    jp: 'ダッシュボード・フォルダーを別のフォルダーに追加することはできません。',
  },
  {
    en: 'Multiple dashboards with the same slug exists',
    jp: '同じスラッグを持つ複数のダッシュボードが存在します。',
  },
  {
    en: 'Failed to generate unique dashboard id',
    jp: 'ユニークなダッシュボードIDの生成に失敗しました。',
  },
  {
    en: 'Dashboard cannot be changed to a folder',
    jp: 'ダッシュボードはフォルダーに変更できません。',
  },
  {
    en: 'Folder name cannot be the same as one of its dashboards',
    jp: 'フォルダー名はその中のダッシュボード名と同じにできません。',
  },
  {
    en: 'Dashboard name cannot be the same as folder',
    jp: 'ダッシュボード名はフォルダー名と同じにできません。',
  },
  {
    en: 'A folder with that name already exists',
    jp: '同じ名前のフォルダーが既に存在します。',
  },
  {
    en: 'Access denied to save dashboard',
    jp: 'ダッシュボードの保存が拒否されました。',
  },
  {
    en: 'uid contains illegal characters',
    jp: 'uidに不正な文字が含まれています。',
  },
  {
    en: 'uid too long, max 40 characters',
    jp: 'uidが長すぎます。最大40文字です。',
  },
  {
    en: 'Cannot save provisioned dashboard',
    jp: 'プロビジョニングされたダッシュボードを保存できません。',
  },
  {
    en: 'Dashboard refresh interval is too low',
    jp: 'ダッシュボード更新間隔が短すぎます。',
  },
  {
    en: 'provisioned dashboard cannot be deleted',
    jp: 'プロビジョニングされたダッシュボードを削除できません。',
  },
  {
    en: 'Unique identifier needed to be able to get a dashboard',
    jp: 'ダッシュボードを取得できるようにユニークなIDが必要です。',
  },
  {
    en: 'Error while retrieving hidden permissions',
    jp: '非表示権限の取得中にエラーが発生しました。',
  },
  {
    en: 'Cannot remove own admin permission for a folder',
    jp: 'フォルダー独自の管理者権限を削除できません。',
  },
  {
    en: 'Failed to create permission',
    jp: '権限の作成に失敗しました。',
  },
  {
    en: 'Dashboard permissions updated',
    jp: 'ダッシュボード権限を更新しました。',
  },
  {
    en: 'Failed to get dashboard snapshot',
    jp: 'スナップショットの取得に失敗しました。',
  },
  {
    en: 'Dashboard snapshot not found',
    jp: 'スナップショットが見つかりません。',
  },
  {
    en: 'Failed to get dashboard data for dashboard snapshot',
    jp: 'スナップショットのダッシュボードデータの取得に失敗しました。',
  },
  {
    en: 'Failed to delete external dashboard',
    jp: '外部ダッシュボードの削除に失敗しました。',
  },
  {
    en: 'Failed to delete dashboard snapshot',
    jp: 'スナップショットの削除に失敗しました。',
  },
  {
    en: 'Error while checking permissions for snapshot',
    jp: 'スナップショットの権限確認中にエラーが発生ました。',
  },
  {
    en: 'Access denied to this snapshot',
    jp: 'スナップショットへのアクセスが拒否されました。',
  },
  {
    en: 'Search failed',
    jp: '検索に失敗しました。',
  },
  {
    en: 'Failed to query datasources',
    jp: 'データソースのクエリーに失敗しました。',
  },
  {
    en: 'Data source not found',
    jp: 'データソースが見つかりません。',
  },
  {
    en: 'data source not found',
    jp: 'データソースが見つかりません。',
  },
  {
    en: 'Datasource id is missing',
    jp: 'データソースIDがありません。',
  },
  {
    en: 'Missing valid datasource id',
    jp: '有効なデータソースIDがありません。',
  },
  {
    en: 'Failed to delete datasource',
    jp: 'データソースの削除に失敗しました。',
  },
  {
    en: 'Cannot delete read-only data source',
    jp: '読取専用のデータソースは削除できません。',
  },
  {
    en: 'Datasource added',
    jp: 'データソースを追加しました。',
  },
  {
    en: 'Datasource updated',
    jp: 'データソースを更新しました。',
  },
  {
    en: 'Data source deleted',
    jp: 'データソースを削除しました。',
  },
  {
    en: 'Missing datasource uid',
    jp: 'データソースuidがありません。',
  },
  {
    en: 'Missing valid datasource name',
    jp: '有効なデータソース名がありません。',
  },
  {
    en: 'Failed to add datasource',
    jp: 'データソースの追加に失敗しました。',
  },
  {
    en: 'Failed to update datasource',
    jp: 'データソースの更新に失敗しました。',
  },
  {
    en: 'Datasource has already been updated by someone else. Please reload and try again',
    jp: 'データソースは既に他利用者によって更新されています。リロードして再試行してください。',
  },
  {
    en: 'Unable to load datasource metadata',
    jp: 'データソースのメタデータを読み込みできません。',
  },
  {
    en: 'Unable to find datasource plugin',
    jp: 'データソース・プラグインが見つかりません。',
  },
  {
    en: 'Unable to get datasource model',
    jp: 'データソース・モデルが取得できません。',
  },
  {
    en: 'Failed to unmarshal detailed response from backend plugin',
    jp: 'バックエンドプラグインからの詳細応答の非直列化に失敗しました。',
  },
  {
    en: 'Folder could not be deleted because it contains linked library panels',
    jp: 'リンクされたライブラリ・パネルを含むため、フォルダーを削除できません。',
  },
  {
    en: 'Folder not found',
    jp: 'フォルダーが見つかりません。',
  },
  {
    en: 'folder not found',
    jp: 'フォルダーが見つかりません。',
  },
  {
    en: 'Folder API error',
    jp: 'フォルダーAPIエラーです。',
  },
  {
    en: 'Folder deleted',
    jp: 'フォルダーを削除しました。',
  },
  {
    en: 'Failed to get folder permissions',
    jp: 'フォルダー権限の取得に失敗しました。',
  },
  {
    en: 'Error while checking folder permissions',
    jp: 'フォルダー権限の確認に失敗しました。',
  },
  {
    en: 'Unauthorized',
    jp: 'ログイン認証してください。',
  },
  {
    en: 'No queries found in query',
    jp: 'クエリーが見つかりません。',
  },
  {
    en: 'Query missing data source ID',
    jp: 'クエリーにデータソースIDがありません。',
  },
  {
    en: 'Metric request error',
    jp: 'メトリック要求エラー',
  },
  {
    en: 'expression request error',
    jp: '式要求エラー',
  },
  {
    en: 'Access denied to data source',
    jp: 'データソースへのアクセスが拒否されました。',
  },
  {
    en: 'Invalid data source ID',
    jp: 'データソースIDが不正です。',
  },
  {
    en: 'Unable to load data source metadata',
    jp: 'データソースのメタデータが読み込めません。',
  },
  {
    en: 'Query missing datasourceId',
    jp: 'クエリーにデータソースIDがありません。',
  },
  {
    en: 'Failed to insert test data',
    jp: 'テストデータの挿入に失敗しました。',
  },
  {
    en: 'Organization not found',
    jp: '組織が見つかりません。',
  },
  {
    en: 'organization not found',
    jp: '組織が見つかりません。',
  },
  {
    en: 'Failed to get organization',
    jp: '組織の取得に失敗しました。',
  },
  {
    en: 'Organization name taken',
    jp: '組織名競合エラー',
  },
  {
    en: 'Failed to create organization',
    jp: '組織の作成に失敗しました。',
  },
  {
    en: 'Failed to update organization',
    jp: '組織の更新に失敗しました。',
  },
  {
    en: 'Organization updated',
    jp: '組織を更新しました。',
  },
  {
    en: 'Failed to update org address',
    jp: '組織アドレスの更新に失敗しました。',
  },
  {
    en: 'Address updated',
    jp: '組織アドレスを更新しました。',
  },
  {
    en: 'Failed to delete organization. ID not found',
    jp: '組織の削除に失敗しました。IDが見つかりません。',
  },
  {
    en: 'Organization deleted',
    jp: '組織を削除しました。',
  },
  {
    en: 'Failed to search orgs',
    jp: '組織の検索に失敗しました。',
  },
  {
    en: 'Failed to get invites from db',
    jp: 'DBからの招待の取得に失敗しました。',
  },
  {
    en: 'Failed to query db for existing user check',
    jp: '既存ユーザーチェックでデータベースにクエリー実行に失敗しました。',
  },
  {
    en: 'Cannot invite when login is disabled.',
    jp: 'ログインが無効の場合は招待できません。',
  },
  {
    en: 'Could not generate random string',
    jp: 'ランダムな文字列が生成できません。',
  },
  {
    en: 'Failed to save invite to database',
    jp: '招待のデータベース保存に失敗しました。',
  },
  {
    en: 'Failed to send email invited_to_org',
    jp: '組織への招待メールの送信に失敗しました。',
  },
  {
    en: 'Failed to send email invite',
    jp: '招待メールの送信に失敗しました。',
  },
  {
    en: 'Failed to update invite with email sent info',
    jp: 'メール送信した情報で招待を更新できませんでした。',
  },
  {
    en: 'Error while trying to create org user',
    jp: '組織ユーザー作成中にエラーが発生しました。',
  },
  {
    en: 'Invite revoked',
    jp: '招待を取り消しました。',
  },
  {
    en: 'Invite not found',
    jp: '招待が見つかりません。',
  },
  {
    en: 'Failed to get invite',
    jp: '招待の取得に失敗しました。',
  },
  {
    en: 'failed to publish event',
    jp: 'イベントの公開に失敗しました。',
  },
  {
    en: 'failed to accept invite',
    jp: '招待の受入に失敗しました。',
  },
  {
    en: 'User not found',
    jp: 'ユーザーが見つかりません。',
  },
  {
    en: 'user not found',
    jp: 'ユーザーが見つかりません。',
  },
  {
    en: 'Could not add user to organization',
    jp: '組織にユーザーを追加できませんでした。',
  },
  {
    en: 'Failed to get users for current organization',
    jp: '現組織からユーザーの取得に失敗しました。',
  },
  {
    en: 'Permission denied',
    jp: '権限がありません。',
  },
  {
    en: 'Failed to get users for organization',
    jp: '組織からユーザーの取得に失敗しました。',
  },
  {
    en: 'Cannot change role so that there is no organization admin left',
    jp: '組織管理者が不在になるロール変更はできません。',
  },
  {
    en: 'Failed update org user',
    jp: '組織ユーザーの更新に失敗しました。',
  },
  {
    en: 'Organization user updated',
    jp: '組織ユーザーを更新しました。',
  },
  {
    en: 'Cannot remove last organization admin',
    jp: '最後の組織管理者は削除できません。',
  },
  {
    en: 'cannot remove last organization admin',
    jp: '最後の組織管理者は削除できません。',
  },
  {
    en: 'User removed from organization',
    jp: '組織からユーザーを削除しました。',
  },
  {
    en: 'Failed to remove user from organization',
    jp: '組織からのユーザー削除に失敗しました。',
  },
  {
    en: 'Not allowed to reset password when login form is disabled',
    jp: 'ログイン無効になっている場合、パスワードをリセットすることはできません。',
  },
  {
    en: 'Email sent',
    jp: 'メールを送信しました。',
  },
  {
    en: 'Failed to send email',
    jp: 'メール送信に失敗しました。',
  },
  {
    en: 'Invalid or expired reset password code',
    jp: '無効または期限切れのパスワードリセットコードです。',
  },
  {
    en: 'Unknown error validating email code',
    jp: 'メールコードの検査で不明なエラーが発生しました。',
  },
  {
    en: 'Passwords do not match',
    jp: 'パスワードが一致しません。',
  },
  {
    en: 'Failed to encode password',
    jp: 'パスワードの暗号化に失敗しました。',
  },
  {
    en: 'Failed to change user password',
    jp: 'パスワードの変更に失敗しました。',
  },
  {
    en: 'User password changed',
    jp: 'パスワードを更新しました。',
  },
  {
    en: 'Playlist not found',
    jp: 'プレイリストが見つかりません。',
  },
  {
    en: 'Could not load playlist items',
    jp: 'プレイリスト・アイテムが読み込めません。',
  },
  {
    en: 'Could not load dashboards',
    jp: 'ダッシュボードが読み込めません。',
  },
  {
    en: 'Failed to delete playlist',
    jp: 'プレイリストの削除に失敗しました。',
  },
  {
    en: 'Failed to create playlist',
    jp: 'プレイリストの作成に失敗しました。',
  },
  {
    en: 'Failed to save playlist',
    jp: 'プレイリストの保存に失敗しました。',
  },
  {
    en: 'Failed to get list of plugins',
    jp: 'プラグインリストの取得に失敗しました。',
  },
  {
    en: 'Plugin not found, no installed plugin with that id',
    jp: 'プラグインが見つかりません。指定IDのプラグインがインストールされていません。',
  },
  {
    en: 'Failed to get login settings',
    jp: 'ログイン設定の取得に失敗しました。',
  },
  {
    en: 'Plugin not installed.',
    jp: 'プラグインがインストールされていません。',
  },
  {
    en: 'Failed to update plugin setting',
    jp: 'プラグイン設定の更新に失敗しました。',
  },
  {
    en: 'Plugin settings updated',
    jp: 'プラグイン設定を更新しました。',
  },
  {
    en: 'Failed to get plugin dashboards',
    jp: 'プラグインダッシュボードの取得に失敗しました。',
  },
  {
    en: 'Could not get markdown file',
    jp: 'MarkDownファイルが取得できません。',
  },
  {
    en: 'Dashboard must be set',
    jp: 'ダッシュボードを設定してください。',
  },
  {
    en: 'Plugin not found',
    jp: 'プラグインが見つかりません。',
  },
  {
    en: 'Failed to get plugin settings',
    jp: 'プラグイン設定の取得に失敗しました。',
  },
  {
    en: 'Plugin health check failed',
    jp: 'プラグイン状態確認に失敗しました。',
  },
  {
    en: 'Plugin unavailable',
    jp: 'プラグインが無効です。',
  },
  {
    en: 'plugin unavailable',
    jp: 'プラグインが無効です。',
  },
  {
    en: 'Plugin request failed',
    jp: 'プラグイン要求に失敗しました。',
  },
  {
    en: 'Failed to set home dashboard',
    jp: 'ホームダッシュボードの設定に失敗しました。',
  },
  {
    en: 'Home dashboard set',
    jp: 'ホームダッシュボードを設定しました。',
  },
  {
    en: 'Failed to save preferences',
    jp: '環境設定の保存に失敗しました。',
  },
  {
    en: 'Preferences updated',
    jp: '環境設定を更新しました。',
  },
  {
    en: 'Quotas not enabled',
    jp: 'クォータが無効です。',
  },
  {
    en: 'Failed to get org quotas',
    jp: '組織クォータの取得に失敗しました。',
  },
  {
    en: 'Invalid quota target',
    jp: 'クォータの対象が不正です。',
  },
  {
    en: 'invalid quota target',
    jp: 'クォータの対象が不正です。',
  },
  {
    en: 'Failed to update org quotas',
    jp: '組織クォータの更新に失敗しました。',
  },
  {
    en: 'Organization quota updated',
    jp: '組織クォータを更新しました。',
  },
  {
    en: 'Limit is above maximum allowed (5000), use page parameter to access hits beyond limit',
    jp: '制限が最大許容値(5000)を超えています。',
  },
  {
    en: 'Path should be relative',
    jp: 'パスは相対パスにしてください。',
  },
  {
    en: 'Failed to create short URL',
    jp: 'ショートURLの作成に失敗しました。',
  },
  {
    en: 'User signup is disabled',
    jp: 'ユーザーのサインアップが無効です。',
  },
  {
    en: 'User with same email address already exists',
    jp: '同じメールアドレスのユーザーが既に存在します。',
  },
  {
    en: 'Failed to generate random string',
    jp: 'ランダムな文字列の生成に失敗しました。',
  },
  {
    en: 'Failed to create signup',
    jp: 'サインアップの生成に失敗しました。',
  },
  {
    en: 'Failed to query database for invites',
    jp: 'データベースへの招待のクエリーに失敗しました。',
  },
  {
    en: 'failed to login user',
    jp: 'ログインに失敗しました。',
  },
  {
    en: 'Missing dashboard id',
    jp: 'ダッシュボードIDが見つかりません。',
  },
  {
    en: 'Failed to star dashboard',
    jp: 'ダッシュボードのお気に入り設定に失敗しました。',
  },
  {
    en: 'Dashboard starred!',
    jp: 'ダッシュボードをお気に入りに設定しました。',
  },
  {
    en: 'Failed to unstar dashboard',
    jp: 'ダッシュボードのお気に入り解除に失敗しました。',
  },
  {
    en: 'Dashboard unstarred',
    jp: 'ダッシュボードのお気に入りを解除しました。',
  },
  {
    en: 'Not allowed to create team.',
    jp: 'チームの生成が許可されていません。',
  },
  {
    en: 'Team name taken',
    jp: 'チーム名競合エラー',
  },
  {
    en: 'Not allowed to view team preferences.',
    jp: 'チーム設定の表示は許可されていません。',
  },
  {
    en: 'Not allowed to update team preferences.',
    jp: 'チーム設定の更新は許可されていません。',
  },
  {
    en: 'Failed to get Team Members',
    jp: 'チームメンバーの取得に失敗しました。',
  },
  {
    en: 'Not allowed to add team member',
    jp: 'チームメンバーの追加は許可されていません。',
  },
  {
    en: 'User is already added to this team',
    jp: 'ユーザーは既にこのチームに追加されています。',
  },
  {
    en: 'Failed to add Member to Team',
    jp: 'チームへのユーザー追加に失敗しました。',
  },
  {
    en: 'Not allowed to update team member',
    jp: 'チームメンバーの更新は許可されていません。',
  },
  {
    en: 'Team member not found.',
    jp: 'チームメンバーが見つかりません。',
  },
  {
    en: 'Team member not found',
    jp: 'チームメンバーが見つかりません。',
  },
  {
    en: 'team member not found',
    jp: 'チームメンバーが見つかりません。',
  },
  {
    en: 'Failed to update team member.',
    jp: 'チームメンバーの更新に失敗しました。',
  },
  {
    en: 'Member added to Team',
    jp: 'チームにメンバーを追加しました。',
  },
  {
    en: 'Team member updated',
    jp: 'チームメンバーを更新しました。',
  },
  {
    en: 'Not allowed to remove team member',
    jp: 'チームメンバーの削除は許可されていません。',
  },
  {
    en: 'Failed to remove Member from Team',
    jp: 'チームメンバーの削除に失敗しました。',
  },
  {
    en: 'Team Member removed',
    jp: 'チームメンバーを削除しました。',
  },
  {
    en: 'Failed to create Team',
    jp: 'チームの生成に失敗しました。',
  },
  {
    en: 'Not allowed to update team',
    jp: 'チームの更新が許可されていません。',
  },
  {
    en: 'Failed to update Team',
    jp: 'チームの更新に失敗しました。',
  },
  {
    en: 'Team created',
    jp: 'チームを作成しました。',
  },
  {
    en: 'Team updated',
    jp: 'チームを更新しました。',
  },
  {
    en: 'Not allowed to delete team',
    jp: 'チームの削除が許可されていません。',
  },
  {
    en: 'Failed to delete Team. ID not found',
    jp: 'チームの削除に失敗しました。IDが見つかりません。',
  },
  {
    en: 'Failed to delete Team',
    jp: 'チームの削除に失敗しました。',
  },
  {
    en: 'Team deleted',
    jp: 'チームを削除しました。',
  },
  {
    en: 'Failed to search Teams',
    jp: 'チームの検索に失敗しました。',
  },
  {
    en: 'Team not found',
    jp: 'チームが見つかりません。',
  },
  {
    en: 'team not found',
    jp: 'チームが見つかりません。',
  },
  {
    en: 'Failed to get Team',
    jp: 'チームの取得に失敗しました。',
  },
  {
    en: 'Not allowed to change email when auth proxy is using email property',
    jp: '認証プロキシがメールプロパティを使用している場合、メールの変更は許可されていません。',
  },
  {
    en: 'Not allowed to change username when auth proxy is using username property',
    jp: '認証プロキシがユーザー名プロパティを使用している場合、ユーザー名の変更は許可されていません。',
  },
  {
    en: 'Not a valid organization',
    jp: '組織が無効です。',
  },
  {
    en: 'Failed to change active organization',
    jp: '組織の有効化に失敗しました。',
  },
  {
    en: 'Active organization changed',
    jp: '組織を更新しました。',
  },
  {
    en: 'Validation error, need to specify either username or email',
    jp: 'アカウントまたはメールアドレスを指定してください。',
  },
  {
    en: 'Failed to update user',
    jp: 'ユーザーの更新に失敗しました。',
  },
  {
    en: 'User updated',
    jp: 'ユーザーを更新しました。',
  },
  {
    en: 'Failed to get user organizations',
    jp: 'ユーザー組織の取得に失敗しました。',
  },
  {
    en: 'Not allowed to change password when LDAP or Auth Proxy is enabled',
    jp: 'LDAPまたは認証プロキシが有効の場合、パスワードを変更することはできません。',
  },
  {
    en: 'Invalid old password',
    jp: '現在のパスワードが不正です。',
  },
  {
    en: 'New password is too short',
    jp: '新しいパスワードが短すぎます。',
  },
  {
    en: 'Failed to fetch users',
    jp: 'ユーザーの取得に失敗しました。',
  },
  {
    en: 'Failed to update help flag',
    jp: 'ヘルプフラグの更新に失敗しました。',
  },
  {
    en: 'Failed to logout user',
    jp: 'ログアウトに失敗しました。',
  },
  {
    en: 'Failed to get user auth tokens',
    jp: 'ユーザー認証トークンの取得に失敗しました。',
  },
  {
    en: 'User auth token not found',
    jp: 'ユーザー認証トークンが見つかりません。',
  },
  {
    en: 'Failed to get user auth token',
    jp: 'ユーザー認証トークンの取得に失敗しました。',
  },
  {
    en: 'Cannot revoke active user auth token',
    jp: 'アクティブユーザーの認証トークン取消はできません。',
  },
  {
    en: 'Failed to revoke user auth token',
    jp: 'ユーザー認証トークンの取消に失敗しました。',
  },
  {
    en: 'Failed to get user',
    jp: 'ユーザーの取得に失敗しました。',
  },
  {
    en: 'Server error',
    jp: 'サーバーエラーです。',
  },
  {
    en: 'Library panel connected',
    jp: 'ライブラリ・パネルに接続しました。',
  },
  {
    en: 'Library panel deleted',
    jp: 'ライブラリ・パネルを削除しました。',
  },
  {
    en: 'Library panel disconnected',
    jp: 'ライブラリ・パネルから切断しました。',
  },
  {
    en: 'invalid condition',
    jp: '状態が不正です。',
  },
  {
    en: 'Failed to evaluate conditions',
    jp: '条件の評価に失敗しました。',
  },
  {
    en: 'Failed to encode result dataframes',
    jp: '結果データフレームのエンコードに失敗しました。',
  },
  {
    en: 'Failed to load alert definition conditions',
    jp: 'アラート定義条件の読込に失敗しました。',
  },
  {
    en: 'Failed to evaluate alert',
    jp: 'アラートの評価に失敗しました。',
  },
  {
    en: 'Failed to instantiate Dataframes from the decoded frames',
    jp: 'デコードされたフレームからのデータフレームのインスタンス化に失敗しました。',
  },
  {
    en: 'Failed to get alert definition',
    jp: 'アラート定義の取得に失敗しました。',
  },
  {
    en: 'Failed to delete alert definition',
    jp: 'アラート定義の削除に失敗しました。',
  },
  {
    en: 'Alert definition deleted',
    jp: 'アラート定義を削除しました。',
  },
  {
    en: 'Failed to update alert definition',
    jp: 'アラート定義の更新に失敗しました。',
  },
  {
    en: 'Failed to create alert definition',
    jp: 'アラート定義の生成に失敗しました。',
  },
  {
    en: 'Failed to list alert definitions',
    jp: 'アラート定義の表示に失敗しました。',
  },
  {
    en: 'Failed to pause scheduler',
    jp: 'スケジューラの一時停止に失敗しました。',
  },
  {
    en: 'Failed to unpause scheduler',
    jp: 'スケジューラの再開に失敗しました。',
  },
  {
    en: 'Failed to pause alert definition',
    jp: 'アラート定義の一時停止に失敗しました。',
  },
  {
    en: 'Failed to unpause alert definition',
    jp: 'アラート定義の再開に失敗しました。',
  },
  {
    en: 'Failed to list alert instances',
    jp: 'アラートインスタンスの表示に失敗しました。',
  },
  {
    en: 'alert definition scheduler paused',
    jp: 'アラート定義スケジューラを一時停止しました。',
  },
  {
    en: 'alert definition scheduler unpaused',
    jp: 'アラート定義スケジューラの一時停止を解除しました。',
  },
  {
    en: ' alert definitions paused',
    jp: '件アラート定義を一時停止しました。',
  },
  {
    en: ' alert definitions unpaused',
    jp: '件アラート定義の一時停止を解除しました。',
  },
  {
    en: 'Failed to delete dashboard',
    jp: 'ダッシュボードの削除に失敗しました。',
  },
  {
    en: 'Folder permissions updated',
    jp: 'フォルダーアクセス権限を更新しました。',
  },
  {
    en: 'Organization created',
    jp: '組織を作成しました。',
  },
  {
    en: 'User created and logged in',
    jp: 'ユーザーを作成しログインに成功しました。',
  },
  {
    en: 'Logged in',
    jp: 'ログイン成功',
  },
  {
    en: 'User is already member of this organization',
    jp: 'ユーザーは既にこの組織に追加されています。',
  },
  {
    en: 'User added to organization',
    jp: '組織にユーザーを追加しました。',
  },
  {
    en: 'User sign up completed successfully',
    jp: 'ユーザーサインアップが成功しました。',
  },
  {
    en: 'User logged out',
    jp: 'ユーザーがログアウトしました。',
  },
  {
    en: 'User auth token revoked',
    jp: 'ユーザー認証トークンを取消しました。',
  },
  {
    en: 'Token revoked',
    jp: 'トークンを取消しました。',
  },
  {
    en: 'User created',
    jp: 'ユーザーを作成しました。',
  },
  {
    en: 'Access denied',
    jp: 'アクセスが拒否されました。',
  },
  {
    en: 'Not found',
    jp: '見つかりません。',
  },
  {
    en: 'Unexpected error',
    jp: '不明なエラーが発生しました。',
  },
  {
    en: 'Failed to fetch dashboard',
    jp: 'ダッシュボードの取得に失敗しました。',
  },
  {
    en: 'could not login user',
    jp: 'ログインできません。',
  },
  {
    en: 'plugin not found, no installed plugin with that id',
    jp: 'プラグインが見つかりません。そのIDのプラグインはインストールされていません。',
  },
  {
    en: 'target URL is not a valid target',
    jp: 'URLが不正です。',
  },
  {
    en: 'deletes not allowed on proxied Prometheus datasource',
    jp: 'プロキシされたPrometheusデータソースでは削除は許可されていません。',
  },
  {
    en: 'puts not allowed on proxied Prometheus datasource',
    jp: 'プロキシされたPrometheusデータソースではputは許可されていません。',
  },
  {
    en: 'posts not allowed on proxied Prometheus datasource except on /query, /query_range, /series and /labels',
    jp:
      '/query, /query_range, /series および /labels を除いてプロキシされたPrometheusデータソースへのpostは許可されていません。',
  },
  {
    en: 'plugin proxy route access denied',
    jp: 'プラグインプロキシルートへのアクセスが拒否されました。',
  },
  {
    en: 'handler not found',
    jp: 'ハンドラが見つかりません。',
  },
  {
    en: 'please specify plugin to install',
    jp: 'インストールするプラグインを指定してください。',
  },
  {
    en: 'missing pluginsDir flag',
    jp: 'プラグイン・ディレクトリ・フラグが見つかりません。',
  },
  {
    en: 'plugin path is not a directory',
    jp: 'プラグイン・パスはディレクトリではありません。',
  },
  {
    en: 'path is not a directory',
    jp: 'パスはディレクトリではありません。',
  },
  {
    en: 'please specify plugin to list versions for',
    jp: 'バージョンを一覧表示するプラグインを指定してください。',
  },
  {
    en: 'missing path flag',
    jp: 'パス・フラグが見つかりません。',
  },
  {
    en: 'missing plugin parameter',
    jp: 'プラグイン・パラメータが見つかりません。',
  },
  {
    en: '"404 not found error"',
    jp: '404エラー。ページが見つかりません。',
  },
  {
    en: 'invalid API key',
    jp: 'APIキーが不正です。',
  },
  {
    en: 'type mismatch',
    jp: 'タイプが不一致です。',
  },
  {
    en: 'invalid value type',
    jp: '値タイプが不正です。',
  },
  {
    en: 'config must have filename',
    jp: 'コンフィグにはファイル名が必要です。',
  },
  {
    en: 'invalid syslog level',
    jp: 'シスログ・レベルが不正です。',
  },
  {
    en: 'invalid syslog facility',
    jp: 'シスログ・ファクトリが不正です。',
  },
  {
    en: 'missing URL',
    jp: 'URLがありません。',
  },
  {
    en: 'label name cannot be empty',
    jp: 'ラベル名を空にできません。',
  },
  {
    en: 'cache item not found',
    jp: 'キャッシュ・アイテムがありません。',
  },
  {
    en: 'invalid remote cache name',
    jp: 'リモート・キャッシュ名が不正です。',
  },
  {
    en: 'required email domain not fulfilled',
    jp: '必要なメールドメインが満たされていません。',
  },
  {
    en: "login provider didn't return an email address",
    jp: 'ログインプロバイダーがメールアドレスを返しませんでした。',
  },
  {
    en: 'login provider denied login request',
    jp: 'ログインプロバイダーがログイン要求を拒否しました。',
  },
  {
    en: 'too many consecutive incorrect login attempts for user - login for user temporarily blocked',
    jp: 'ログイン試行エラーが多すぎます - ユーザーのログインが一時的にブロックされました。',
  },
  {
    en: 'no password provided',
    jp: 'パスワードが提供されていません。',
  },
  {
    en: 'user is disabled',
    jp: 'ユーザーが無効です。',
  },
  {
    en: 'absolute URLs are not allowed for redirect_to cookie value',
    jp: 'クッキー値 redirect_to に実URLは許可されていません。',
  },
  {
    en: 'invalid redirect_to cookie value',
    jp: 'クッキー値 redirect_to が不正です。',
  },
  {
    en: 'forbidden redirect_to cookie value',
    jp: 'クッキー値 redirect_to は禁止されています。',
  },
  {
    en: 'error getting user info: no email found in access token',
    jp: 'ユーザー情報の取得中にエラーが発生しました：アクセストークンにメールが見つかりません。',
  },
  {
    en: 'no attribute path specified',
    jp: 'パス属性を指定してください。',
  },
  {
    en: 'empty user info JSON response provided',
    jp: 'ユーザー情報JSONの応答が空です。',
  },
  {
    en: 'user not a member of one of the required teams',
    jp: 'ユーザーは要求されたチームのメンバーではありません。',
  },
  {
    en: 'user not a member of one of the required organizations',
    jp: 'ユーザーは要求された組織のメンバーではありません。',
  },
  {
    en: 'notification frequency not specified',
    jp: '通知頻度が指定されていません。',
  },
  {
    en: 'alert notification state update version conflict',
    jp: 'アラート通知状態の更新でバージョン競合が発生しました。',
  },
  {
    en: 'failed to generate unique alert notification uid',
    jp: 'ユニークなアラート通知uidの生成に失敗しました。',
  },
  {
    en: 'failed to translate Notification Id to Uid',
    jp: '通知IDをUidに変換できませんでした。',
  },
  {
    en: 'alert notification with same name already exists',
    jp: '同じ名前のアラート通知が既に存在します。',
  },
  {
    en: 'alert notification with same uid already exists',
    jp: '同じuidのアラート通知が既に存在します。',
  },
  {
    en: 'API key, organization ID and name must be unique',
    jp: 'APIキー、組織ID、および名前は一意である必要があります。',
  },
  {
    en: 'user id and team id cannot both be empty for a dashboard permission',
    jp: 'ダッシュボードのアクセス権限でユーザーIDとチームIDの両方を空にはできません。',
  },
  {
    en: 'dashboard id must be greater than zero for a dashboard permission',
    jp: 'ダッシュボードIDはゼロより大きい値にしてください。',
  },
  {
    en: 'user id and team id cannot both be empty for a folder permission',
    jp: 'フォルダーのアクセス権限でユーザーIDとチームIDの両方を空にはできません。',
  },
  {
    en: 'folder id must be greater than zero for a folder permission',
    jp: 'フォルダーIDはゼロより大きい値にしてください。',
  },
  {
    en: 'team and user permissions cannot have an associated role',
    jp: 'チームとユーザーの権限に関連付けられたロールを設定できません。',
  },
  {
    en: 'no dashboard versions found for the given DashboardId',
    jp: '指定されたダッシュボードIdのダッシュボード版数が見つかりません。',
  },
  {
    en: 'data source with the same name already exists',
    jp: '同じ名前のデータソースが既に存在します。',
  },
  {
    en: 'data source with the same uid already exists',
    jp: '同じuidのデータソースが既に存在します。',
  },
  {
    en: 'trying to update old version of datasource',
    jp: '古いバージョンのデータソースを更新しようとしています。',
  },
  {
    en: 'data source is readonly, can only be updated from configuration',
    jp: 'データソースは読取専用です。コンフィグから更新してください。',
  },
  {
    en: 'data source access denied',
    jp: 'データソースへのアクセスが拒否されました。',
  },
  {
    en: 'folder title cannot be empty',
    jp: 'フォルダー名は空にできません。',
  },
  {
    en: 'failed to generate unique datasource ID',
    jp: 'ユニークなデータソースIDの生成に失敗しました。',
  },
  {
    en: 'unique identifier and org id are needed to be able to get or delete a datasource',
    jp: 'データソースを取得または削除するには、ユニークなIDと組織IDが必要です。',
  },
  {
    en: 'failed to parse TLS CA PEM certificate',
    jp: 'TLS CAPEM証明書の解析に失敗しました。',
  },
  {
    en: 'a folder/dashboard with the same uid already exists',
    jp: '同じuidのフォルダー/ダッシュボードが既に存在します。',
  },
  {
    en: 'a folder or dashboard in the general folder with the same name already exists',
    jp: '同じ名前のフォルダーまたはダッシュボードが既に存在します。',
  },
  {
    en: 'failed to generate unique folder ID',
    jp: 'ユニークなフォルダーIDの生成に失敗しました。',
  },
  {
    en: 'access denied to folder',
    jp: 'フォルダーへのアクセスが拒否されました。',
  },
  {
    en: 'invalid or expired email code',
    jp: '無効または期限切れのメールコードです。',
  },
  {
    en: 'cannot find the organization user',
    jp: '組織のユーザーが見つかりません。',
  },
  {
    en: 'user is already added to organization',
    jp: 'ユーザーは既に組織に追加されています。',
  },
  {
    en: 'plugin setting not found',
    jp: 'プラグイン設定が見つかりません。',
  },
  {
    en: 'short URL not found',
    jp: 'ショートURLが見つかりません。',
  },
  {
    en: 'command missing required fields',
    jp: '要求されたフィールドにコマンドがありません。',
  },
  {
    en: 'not allowed to remove last admin',
    jp: '最後の管理ユーザーは削除できません。',
  },
  {
    en: 'user not allowed to update team',
    jp: 'チームの更新は許可されていません。',
  },
  {
    en: 'user not allowed to update team in another org',
    jp: '別組織のチームを更新できません。',
  },
  {
    en: 'user already exists',
    jp: 'ユーザーは既に存在します。',
  },
  {
    en: 'cannot remove last grafana admin',
    jp: '最後のGrafana管理ユーザーは削除できません。',
  },
  {
    en: 'user token not found',
    jp: 'ユーザートークンが見つかりません。',
  },
  {
    en: 'unable to decode manifest',
    jp: 'マニフェストをデコードできません。',
  },
  {
    en: 'did not find type or id properties in plugin.json',
    jp: 'plugin.jsonでタイプまたはIDプロパティが見つかりません。',
  },
  {
    en: 'plugin not registered',
    jp: 'プラグインが登録されていません。',
  },
  {
    en: 'health check failed',
    jp: 'ヘルスチェックに失敗しました。',
  },
  {
    en: 'method not implemented',
    jp: 'メソッドは実装されていません。',
  },
  {
    en: 'backend plugin is managed and cannot be manually started',
    jp: 'バックエンド・プラグインは管理されており、手動開始はできません。',
  },
  {
    en: 'received empty resource response',
    jp: '空のリソース応答を受信しました。',
  },
  {
    en: 'cannot send to a closed stream',
    jp: '閉じたストリームに送信できません。',
  },
  {
    en: 'cancelled',
    jp: 'キャンセルしました。',
  },
  {
    en: 'cannot close a closed stream',
    jp: '閉じたストリームを閉じることはできません。',
  },
  {
    en: 'no compatible plugin implementation found',
    jp: '互換性のあるプラグインの実装が見つかりません。',
  },
  {
    en: '"evaluate every" cannot be zero or below',
    jp: '"すべてを評価"はゼロ以下にできません。',
  },
  {
    en: 'library panel with that name already exists',
    jp: '同じ名前のライブラリパネルが既に存在します。',
  },
  {
    en: 'library panel could not be found',
    jp: 'ライブラリパネルが見つかりません。',
  },
  {
    en: 'library panel connection could not be found',
    jp: 'ライブラリパネル・コネクションが見つかりません。',
  },
  {
    en: 'library panel header is missing required property uid',
    jp: 'ライブラリパネルヘッダーに必要なプロパティuidがありません。',
  },
  {
    en: 'library panel header is missing required property name',
    jp: 'ライブラリパネルヘッダーに必要なプロパティ名がありません。',
  },
  {
    en: 'folder contains library panels that are linked to dashboards',
    jp: 'フォルダーには、ダッシュボードにリンクされているライブラリパネルが含まれています。',
  },
  {
    en: 'the library panel has been changed by someone else',
    jp: '他の人によってライブラリパネルが変更されています。',
  },
  {
    en: 'the library panel is linked to dashboards',
    jp: 'ライブラリパネルはダッシュボードにリンクされています。',
  },
  {
    en: 'permission already exists',
    jp: '権限は既に存在します。',
  },
  {
    en: 'you can only override a permission to be higher',
    jp: '権限の更新はより高度に設定することしかできません。',
  },
  {
    en: 'missing timerange',
    jp: '時間範囲が見つかりません。',
  },
  {
    en: 'did not find a user',
    jp: 'ユーザーが見つかりません。',
  },
  {
    en: 'failed to generate alert definition UID',
    jp: 'アラート定義UIDの生成に失敗しました。',
  },
  {
    en: 'title is empty',
    jp: 'タイトルが空です。',
  },
  {
    en: 'folder name missing',
    jp: 'フォルダー名が見つかりません。',
  },
  {
    en: 'timeout error - you can set timeout in seconds with &timeout url parameter',
    jp: 'タイムアウトエラー - &timeouturl パラメータを使用してタイムアウト[秒]を設定できます。',
  },
  {
    en: 'annotation not found',
    jp: '注釈が見つかりません。',
  },
  {
    en: 'invalid annotations query',
    jp: '注釈クエリーが不正です。',
  },
  {
    en: 'invalid attribute path',
    jp: 'パス属性が不正です。',
  },
  {
    en: 'invalid query - inspect Grafana server log for details',
    jp: '無効なクエリー - 詳細はGrafanaサーバーログを確認してください。',
  },
  {
    en: 'query failed - please inspect Grafana server log for details',
    jp: 'クエリー失敗 - 詳細はGrafanaサーバーログを確認してください。',
  },
  {
    en: "'httpMode' should be either 'GET' or 'POST'",
    jp: 'httpモードは GET または POST にしてください。',
  },
  {
    en: 'invalid basic auth header',
    jp: 'ベーシック認証ヘッダーが不正です。',
  },
  {
    en: 'payload too short',
    jp: 'ペイロードが短すぎます。',
  },
  {
    en: 'no query target found for the alert rule',
    jp: 'アラート・ルールに対するクエリーが見つかりません。',
  },
  {
    en: 'datasource.yaml config is invalid. Only one datasource per organization can be marked as default',
    jp: 'datasource.yaml設定が無効です。デフォルトとしてマークできるデータソースは組織毎に1つです。',
  },
  {
    en: `Snapshot deleted. It might take an hour before it's cleared from any CDN caches.`,
    jp: 'スナップショットを削除しました。CDNキャッシュからクリアされるまで1時間かかる場合があります。',
  },
  {
    en: 'Notification deleted',
    jp: '通知を削除しました。',
  },
  {
    en: '-- Grafana -- plugin failed',
    jp: 'プラグインエラー',
  },
  {
    en: 'ChunkLoadError: ',
    jp: 'チャンク読込失敗: ',
  },
];
