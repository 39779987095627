import { BigValueTextMode, sharedSingleStatMigrationHandler } from '@grafana/ui';
import { PanelPlugin } from '@grafana/data';
import { addStandardDataReduceOptions, StatPanelOptions } from './types';
import { StatPanel } from './StatPanel';
import { statPanelChangedHandler } from './StatMigrations';

export const plugin = new PanelPlugin<StatPanelOptions>(StatPanel)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    addStandardDataReduceOptions(builder);

    builder.addSelect({
      path: 'textMode',
      name: 'テキストモード',
      description: '名前と値を表示する、もしくは名前だけを表示します',
      settings: {
        options: [
          { value: BigValueTextMode.Auto, label: '自動' },
          { value: BigValueTextMode.Value, label: '値' },
          { value: BigValueTextMode.ValueAndName, label: '値と名前' },
          { value: BigValueTextMode.Name, label: '名前' },
          { value: BigValueTextMode.None, label: 'None' },
        ],
      },
      defaultValue: 'auto',
    });

    builder
      .addRadio({
        path: 'colorMode',
        name: '色モード',
        description: '値もしくは背景に色を付けます',
        defaultValue: 'value',
        settings: {
          options: [
            { value: 'value', label: '値色' },
            { value: 'background', label: '背景色' },
          ],
        },
      })
      .addRadio({
        path: 'graphMode',
        name: 'グラフモード',
        description: 'Statパネルにグラフ/スパークラインを表示します',
        defaultValue: 'area',
        settings: {
          options: [
            { value: 'none', label: 'なし' },
            { value: 'area', label: '領域' },
          ],
        },
      })
      .addRadio({
        path: 'justifyMode',
        name: '配置モード',
        description: '値とタイトルの表示位置を指定します',
        defaultValue: 'auto',
        settings: {
          options: [
            { value: 'auto', label: '自動' },
            { value: 'center', label: '中央' },
          ],
        },
      });
  })
  .setNoPadding()
  .setPanelChangeHandler(statPanelChangedHandler)
  .setMigrationHandler(sharedSingleStatMigrationHandler);
